import React, { Fragment, useRef } from "react"
import { Link } from "gatsby"
import { Popover, Transition } from "@headlessui/react"
import {
  MenuIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  XIcon,
} from '@heroicons/react/outline'
import { useSiteContext } from "../contexts/site";
import { useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



function Navigation({ conditions = [], treatments }) {

  const items = {
    en: [
      {
        title: 'About Chiropractic',
        href: '/en/about-chiropractic',
        children: {
          en: [
            {
              title: "About Chiropractic Overview",
              href: '/en/about-chiropractic',
            },
            {
              title: "What is Chiropractic?",
              href: "/en/about-chiropractic/what-is"
            },
            {
              title: "History of Chiropractic",
              href: "/en/about-chiropractic/history"
            },
            {
              title: "Is it Right for Me?",
              href: "/en/about-chiropractic/for-me"
            },
            {
              title: "Case Studies",
              href: "/en/about-chiropractic/case-studies"
            },
            { title: 'Exercises', href: '/en/about-chiropractic/exercises' },
            { title: 'FAQs', href: '/en/about-chiropractic/faqs', },
            { title: 'Back to main menu', href: '#' }
          ],
        }
      },
      {
        title: 'Common Conditions',
        href: '/en/conditions',
        children: {
          en: [
            {
              title: "Common Conditions Overview",
              href: '/en/conditions',
            },
            , ...conditions,
            {
              title: 'Back to main menu',
              href: '#'
            }
          ]
        }
      },
      {
        title: 'Treatment Methods',
        href: '/en/treatments',
        children: {
          en: [
            {
              title: "Treatment Methods Overview",
              href: '/en/treatments',
            },
            ...treatments,
            { title: 'Back to main menu', href: '#' }
          ]
        }
      },
      {
        title: 'What to Expect',
        href: '/en/what-to-expect',
        children: {
          en: [
            {
              title: 'Your First Visit',
              href: '/en/what-to-expect/first-visit'
            },
            {
              title: 'Personalized Care',
              href: '/en/what-to-expect/personalized-care'
            },
            {
              title: 'Fees & Coverages',
              href: '/en/what-to-expect/fees'
            },
            { title: 'Back to main menu', href: '#' }
          ]
        }
      },
      {
        title: 'Back Health Blog',
        href: '/en/back-health-blog'
      },
      {
        title: 'About Us',
        href: '/en/about-us/philosophy',
        children: {
          en: [
            {
              title: 'Our Philosophy',
              href: '/en/about-us/philosophy'
            },
            {
              title: 'Meet Our Team',
              href: '/en/about-us/team'
            },
            {
              title: 'Contact Us',
              href: '/en/about-us/contact'
            },
            { title: 'Back to main menu', href: '#' }
          ]
        }
      }
    ],
    ch: [
      {
        title: '脊科醫術',
        href: '/ch/about-chiropractic',
        children: {
          ch: [
            {
              title: '脊科醫術概覽',
              href: '/ch/about-chiropractic',
            },
            {
              title: "認識脊科醫術",
              href: "/ch/about-chiropractic/what-is"
            },
            {
              title: "脊科醫術的歷史",
              href: "/ch/about-chiropractic/history"
            },
            {
              title: "脊科治療適合我嗎？",
              href: "/ch/about-chiropractic/for-me"
            },
            {
              title: "臨床個案探討",
              href: "/ch/about-chiropractic/case-studies"
            },
            { title: '保健運動', href: '/ch/about-chiropractic/exercises', },
            { title: '常見問題', href: '/ch/about-chiropractic/faqs', },
            { title: '返回主目錄', href: '#' }
          ]
        }
      },
      {
        title: '常見痛症',
        href: '/ch/conditions',
        children: {
          ch: [
            {
              title: "常見痛症概覽",
              href: '/ch/conditions',
            },
            ...conditions,
            { title: '返回主目錄', href: '#' }
          ]
        }
      },
      {
        title: '治療方法',
        href: '/ch/treatments',
        children: {
          ch: [
            {
              title: "治療方法概覽",
              href: '/ch/treatments',
            },
            ...treatments,
            { title: '返回主目錄', href: '#' }
          ]
        }
      },
      {
        title: '就診指南',
        href: '/ch/what-to-expect',
        children: {
          ch: [
            {
              title: '首次會診',
              href: '/ch/what-to-expect/first-visit'
            },
            {
              title: '個人化護理',
              href: '/ch/what-to-expect/personalized-care'
            },
            {
              title: '收費及醫療保險',
              href: '/ch/what-to-expect/fees'
            },
            { title: '返回主目錄', href: '#' }
          ]
        }
      },
      {
        title: '護脊訊息',
        href: '/ch/back-health-blog'
      },
      {
        title: '關於我們',
        href: '/ch/about-us/philosophy',
        children: {
          ch: [
            {
              title: '我們的信念',
              href: '/ch/about-us/philosophy'
            },
            {
              title: '我們的團隊',
              href: '/ch/about-us/team'
            },
            {
              title: '聯絡我們',
              href: '/ch/about-us/contact'
            },
            { title: '返回主目錄', href: '#' }
          ]
        }
      }
    ]
  }
  const { locale } = useSiteContext()
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMenuItems, setMobileMenuItems] = useState(items);
  const [conditionMenuItems, setConditionMenuItems] = useState(conditions)
  const [displaySubMenu, setDisplaySubMenu] = useState(false)
  const aboutChiropracticButtonRef = useRef(null);
  const commonConditionsButtonRef = useRef(null);
  const treatmentMethodsButtonRef = useRef(null);
  const visitButtonRef = useRef(null);
  const aboutUsRef = useRef(null);

  const aboutUsMenu = {
    en: [
      {
        title: 'Our Philosophy',
        href: '/en/about-us/philosophy'
      },
      {
        title: 'Meet Our Team',
        href: '/en/about-us/team'
      },
      {
        title: 'Contact Us',
        href: '/en/about-us/contact'
      }
    ],
    ch: [
      {
        title: '我們的信念',
        href: '/ch/about-us/philosophy'
      },
      {
        title: '我們的團隊',
        href: '/ch/about-us/team'
      },
      {
        title: '聯絡我們',
        href: '/ch/about-us/contact'
      }
    ]
  }

  const whatToExpectMenu = {
    en: [
      {
        title: 'Your First Visit',
        href: '/en/what-to-expect/first-visit'
      },
      {
        title: 'Personalized Care',
        href: '/en/what-to-expect/personalized-care'
      },
      {
        title: 'Fees & Coverages',
        href: '/en/what-to-expect/fees'
      }
    ],
    ch: [
      {
        title: '首次會診',
        href: '/ch/what-to-expect/first-visit'
      },
      {
        title: '個人化護理',
        href: '/ch/what-to-expect/personalized-care'
      },
      {
        title: '收費及醫療保險',
        href: '/ch/what-to-expect/fees'
      }
    ]
  }


  const aboutChiropracticItems = {
    ch: [
      {
        name: "認識脊科醫術",
        href: "/ch/about-chiropractic/what-is"
      },
      {
        name: "脊科醫術的歷史",
        href: "/ch/about-chiropractic/history"
      },
      {
        name: "脊科治療適合我嗎？",
        href: "/ch/about-chiropractic/for-me"
      },
      {
        name: "臨床個案探討",
        href: "/ch/about-chiropractic/case-studies"
      },
      { name: '保健運動', href: '/ch/about-chiropractic/exercises', },
      { name: '常見問題', href: '/ch/about-chiropractic/faqs', },
    ],
    en: [
      {
        name: "What is Chiropractic?",
        href: "/en/about-chiropractic/what-is"
      },
      {
        name: "History of Chiropractic",
        href: "/en/about-chiropractic/history"
      },
      {
        name: "Is it Right for Me?",
        href: "/en/about-chiropractic/for-me"
      },
      {
        name: "Case Studies",
        href: "/en/about-chiropractic/case-studies"
      },
      { name: 'Exercises', href: '/en/about-chiropractic/exercises' },
      { name: 'FAQs', href: '/en/about-chiropractic/faqs', },
    ]
  }
  const aboutChiropracticCallsToAction = {
    ch: [
      // { name: '保健運動', href: '/ch/about-chiropractic/exercises', icon: FireIcon },
      // { name: '常見問題', href: '/ch/about-chiropractic/faqs', icon: QuestionMarkCircleIcon },
      { name: '脊科醫術概覽', href: '/ch/about-chiropractic' }
    ],
    en: [
      // { name: 'Exercises', href: '/en/about-chiropractic/exercises', icon: FireIcon },
      // { name: 'FAQs', href: '/en/about-chiropractic/faqs', icon: QuestionMarkCircleIcon },
      { name: 'About Chiropractic Overview', href: '/en/about-chiropractic' }
    ]
  }
  const translations = {
    en: {
      'about-chiropractic': {
        title: 'About Chiropractic',
      },
      'what-is-chiropractic': {
        title: 'What is Chiropractic?',
      },
      'common-conditions': {
        title: 'Common Conditions'
      },
      'treatment-methods': {
        title: 'Treatment Methods'
      },
      'what-to-expect': {
        title: 'What to Expect'
      },
      'back-health-blog': {
        title: 'Back Health Blog',
        href: '/en/back-health-blog'
      },
      'about-us': {
        title: 'About Us'
      },
      'all-conditions': {
        title: 'Common Conditions Overview',
        href: '/en/conditions'
      },
      'all-treatments': {
        title: 'Treatments Overview',
        href: '/en/treatments'
      }
    },
    ch: {
      'about-chiropractic': {
        title: '脊科醫術'
      },
      'common-conditions': {
        title: '常見痛症'
      },
      'treatment-methods': {
        title: '治療方法'
      },
      'what-to-expect': {
        title: '就診指南'
      },
      'back-health-blog': {
        title: '護脊訊息',
        href: '/ch/back-health-blog'
      },
      'about-us': {
        title: '關於我們'
      },
      'all-conditions': {
        title: '常見痛症概覽',
        href: '/ch/conditions'
      },
      'all-treatments': {
        title: '治療方法概覽',
        href: '/ch/treatments'
      }

    }
  }

  return (
    <Popover className="relative bg-white">
      {({ open }) => (
        <>
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/">
                  <span className="sr-only">Hong Kong Spine Centre</span>
                  <img
                    className="h-20 md:w-48 w-20"
                    src="/assets/images/logo.svg"
                    alt="Hong Kong Spine Centre Logo"
                  />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <button onClick={() => setMobileMenuOpen(!isMobileMenuOpen)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mango">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-10">
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        ref={aboutChiropracticButtonRef}
                        onMouseOver={() => {
                          aboutChiropracticButtonRef?.current.click()
                        }}
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-lg font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mango'
                        )}
                      >
                        <span>{translations[locale]['about-chiropractic']['title']}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-30 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {aboutChiropracticItems[locale].map((item) => (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                >
                                  {/* <item.icon className="flex-shrink-0 h-6 w-6 text-mango" aria-hidden="true" /> */}
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                    {/* <p className="mt-1 text-sm text-gray-500">{item.description}</p> */}
                                  </div>
                                </Link>
                              ))}
                            </div>
                            <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                              {aboutChiropracticCallsToAction[locale].map((item) => (
                                <div key={item.name} className="flow-root">
                                  <Link
                                    to={item.href}
                                    className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                  >
                                    {/* <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" /> */}
                                    <span className="ml-3">{item.name}</span>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        ref={commonConditionsButtonRef}
                        onMouseOver={() => {
                          commonConditionsButtonRef?.current.click()
                        }}
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-lg font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mango'
                        )}
                      >
                        <span>{translations[locale]['common-conditions']['title']}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-30 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 lg:max-w-3xl"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                              {conditionMenuItems.map((item) => (
                                <Link
                                  key={item.id}
                                  to={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <img src={item.thumbnail} alt={item.title} className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-12 sm:w-12" />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.title}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                            <div className="p-5 bg-gray-50 sm:p-8">
                              <a
                                href={translations[locale]['all-conditions']['href']}
                                className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
                              >
                                <span className="flex items-center">
                                  <span className="text-base font-medium text-gray-900">{translations[locale]['all-conditions']['title']}</span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        ref={treatmentMethodsButtonRef}
                        onMouseOver={() => {
                          treatmentMethodsButtonRef?.current.click()
                        }}
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-lg font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mango'
                        )}
                      >
                        <span>{translations[locale]['treatment-methods']['title']}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-30 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 lg:max-w-3xl"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                              {treatments.map((item) => (
                                <Link
                                  key={item.id}
                                  to={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <img src={item.thumbnail} alt={item.title} className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-12 sm:w-12" />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.title}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                            <div className="p-5 bg-gray-50 sm:p-8">
                              <a
                                href={translations[locale]['all-treatments']['href']}
                                className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
                              >
                                <span className="flex items-center">
                                  <span className="text-base font-medium text-gray-900">{translations[locale]['all-treatments']['title']}</span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        ref={visitButtonRef}
                        onMouseOver={() => {
                          visitButtonRef?.current.click()
                        }}
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-lg font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mango'
                        )}
                      >
                        <span>{translations[locale]['what-to-expect']['title']}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-30 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {whatToExpectMenu[locale].map((item) => (
                                <a
                                  key={item.title}
                                  href={item.href}
                                  className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <p className="text-base font-medium text-gray-900">{item.title}</p>
                                </a>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                <a href={translations[locale]['back-health-blog']['href']} className="text-lg font-medium text-gray-500 hover:text-gray-900">
                  {translations[locale]['back-health-blog']['title']}
                </a>

                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        ref={aboutUsRef}
                        onMouseOver={() => {
                          aboutUsRef?.current.click()
                        }}
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-lg font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mango'
                        )}
                      >
                        <span>{translations[locale]['about-us']['title']}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-30 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {aboutUsMenu[locale].map((item) => (
                                <a
                                  key={item.title}
                                  href={item.href}
                                  className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <p className="text-base font-medium text-gray-900">{item.title}</p>
                                </a>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </Popover.Group>
            </div>
          </div>

          <Transition
            show={isMobileMenuOpen}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-30">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 bg-opacity-75 bg-clip-padding backdrop-filter backdrop-blur-md">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-12 w-auto"
                        src="/assets/images/logo.svg"
                        alt="Hong Kong Spine Centre Logo"
                      />
                    </div>
                    <div className="-mr-2">
                      <button onClick={() => {
                        setMobileMenuOpen(false)
                        setDisplaySubMenu(false)
                        setMobileMenuItems(items)
                      }} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mango">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className={`grid ${displaySubMenu ? 'grid-cols-2' : ''} gap-y-6`}>
                      {
                        mobileMenuItems[locale].map((item) => {
                          if (item.children) {
                            return (
                              <a key={item.href} onClick={() => {
                                setMobileMenuItems(item.children)
                                setDisplaySubMenu(true)
                              }} className="-m-3 p-3 flex items-center justify-between rounded-md hover:bg-gray-50">
                                <span className="ml-3 text-base font-medium text-gray-900">{item.title}</span>
                                <ChevronRightIcon className="w-6 h-6 text-mango" />
                              </a>
                            )
                          }
                          if (item.href === '#') {
                            return (
                              <a
                                key={item.title}
                                onClick={(e) => {
                                  setMobileMenuItems(items)
                                  setDisplaySubMenu(false)
                                }}
                                className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 col-span-2"
                              >
                                <ChevronLeftIcon className="w-6 h-6 text-mango" />
                                <span className="ml-3 text-base font-medium text-gray-900">{item.title}</span>
                              </a>
                            )
                          }
                          return (
                            <Link
                              key={item.href}
                              to={item.href}
                              className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                            >
                              <span className="ml-3 text-base font-medium text-gray-900">{item.title}</span>
                            </Link>
                          )
                        })
                      }
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default Navigation