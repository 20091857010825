import React, { useContext } from "react"

const SiteContext = React.createContext({})

export const useSiteContext = () => useContext(SiteContext)

export function SiteContextProvider ({ children, data }) {
  return (
    <SiteContext.Provider value={data}>
      { children }
    </SiteContext.Provider>
  )
}