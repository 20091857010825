import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useSiteContext } from "../contexts/site";

function SiteFooter({ conditions, treatments }) {
  const { locale } = useSiteContext();
  const data = useStaticQuery(graphql`
  query siteFooter {
    firestore {
      en: settings(locale: "en") {
        tel
        email
        disclaimer
        openingHours
        name
        whatsapp
        address
        map {
          url
        }
      }
      ch: settings(locale: "ch") {
        tel
        email
        disclaimer
        openingHours
        name
        whatsapp
        address
        map {
          url
        }
      }
    }
  }
  `);
  const currentYear = (new Date()).getFullYear();
  const translations = {
    en: {
      conditionTitle: 'Common Conditions',
      treatmentTitle: 'Treatment Methods',
      aboutUsTitle: 'About Us',
      contactUsTitle: 'Contact Us',
      location: 'Our Location',
      openHoursTitle: 'Our Hours',
      copyright: `HK Spine Centre ${currentYear}`,
      whatis: 'About Chiropractic',
      fees: 'Fees & Coverages',
      faq: 'FAQs',
      team: 'Our Team',
      philosophy: 'Our Philosophy'
    },
    ch: {
      conditionTitle: '常見痛症',
      treatmentTitle: '治療方法',
      location: '診所位置',
      aboutUsTitle: '關於我們',
      contactUsTitle: '聯絡我們',
      openHoursTitle: '診所時間',
      copyright: `${currentYear} 香港護脊中心 (脊骨神經科醫生)`,
      whatis: '關於脊科醫術',
      fees: '收費及醫療保險',
      faq: '常見問題',
      team: '我們的團隊',
      philosophy: '我們的信念'
    }
  }

  return (
    <footer className="flex flex-col bg-mango py-12 px-6 lg:px-36">
      <section className="flex flex-col lg:flex-row justify-between text-white text-center lg:text-left space-y-8 lg:space-y-0">
        <div className="w-full lg:w-3/12">
          <h3 className="text-2xl mb-8 font-light">
            <a href={`/${locale}/conditions`}>{translations[locale]['conditionTitle']}</a>
          </h3>
          <ul className="space-y-2">
            {
              conditions.map(item => (
                <li key={item.id}>
                  <a href={item.href}>{item.title}</a>
                </li>
              ))
            }
          </ul>
          {/* <h3 className="text-2xl mb-8 font-light">{data.firestore[locale]["name"]}</h3> */}
          {/* <p className="text-gray-200">{data.firestore[locale]["disclaimer"]}</p> */}
        </div>
        <div className="w-full lg:w-3/12">
          <h3 className="text-2xl mb-8 font-light">
            <a href={`/${locale}/treatments`}>{translations[locale]['treatmentTitle']}</a>
          </h3>
          <ul className="space-y-2">
            {
              treatments.map(item => (
                <li key={item.id}>
                  <a href={item.href}>{item.title}</a>
                </li>
              ))
            }
          </ul>
          {/* <h3 className="text-2xl mb-8 font-light">{data.firestore[locale]["name"]}</h3> */}
          {/* <p className="text-gray-200">{data.firestore[locale]["disclaimer"]}</p> */}
        </div>
        <div className="w-full lg:w-3/12">
          <h3 className="text-2xl mb-8 font-light">{translations[locale]['aboutUsTitle']}</h3>
          <ul className="space-y-2">
            <li>
              <a href={`/${locale}/about-us/philosophy`}>
                {translations[locale]['philosophy']}
              </a>
            </li>
            <li>
              <a href={`/${locale}/about-us/team`}>
                {translations[locale]['team']}
              </a>
            </li>
            <li>
              <a href={`/${locale}/about-chiropractic/what-is`}>
                {translations[locale]['whatis']}
              </a>
            </li>
            <li>
              <a href={`/${locale}/what-to-expect/fees`}>
                {translations[locale]['fees']}
              </a>
            </li>
            <li>
              <a href={`/${locale}/about-chiropractic/faqs`}>
                {translations[locale]['faq']}
              </a>
            </li>
          </ul>
        </div>
        <div className="lg:w-3/12 w-full">
          <h3 className="text-2xl mb-8 font-light">
            <a href={`/${locale}/about-us/contact`}>
              {translations[locale]['contactUsTitle']}
            </a>
          </h3>
          <h4 className="text-lg mb-8 font-light">{translations[locale]['openHoursTitle']}</h4>
          <ul className="text-gray-200 space-y-1 flex flex-col items-center lg:items-start mb-4">
            {
              data.firestore[locale]['openingHours'].map(item => (
                <li className="flex items-center" key={item}>
                  <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                  </svg>
                  <span className="ml-2">{item}</span>
                </li>
              ))
            }
          </ul>
          <h4 className="text-lg mb-8 font-light">{translations[locale]['location']}</h4>
          <ul className="text-gray-200 space-y-1 flex flex-col items-center lg:items-start">
            <li className="flex items-center">
              <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
              </svg>
              <a target="_blank" rel="noreferrer" href={data.firestore[locale]["map"]["url"]} className="ml-2">{data.firestore[locale]['address']}</a>
            </li>
            <li className="flex items-center">
              <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
              <a className="ml-2" href={`tel:${data.firestore[locale]["tel"].split(" ").join("")}`}>{data.firestore[locale]["tel"]}</a>
            </li>
            <li className="flex items-center">
              <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              <a className="ml-2" href={`mailto:${data.firestore[locale]["email"]}`}>
                {data.firestore[locale]["email"]}
              </a>
            </li>
          </ul>
        </div>
      </section>
      <span className="text-gray-200 mt-4 text-center lg:text-left">&copy; {translations[locale]['copyright']}</span>
    </footer>
  )
}

export default SiteFooter